<template>
  <AModalContent
    class="ape-constructor-analyze-modal"
    :show-close="true"
  >
    <div class="ape-constructor-analyze-modal-icon">
      <IAnalyze width="30" />
    </div>
    <div class="ape-constructor-analyze-modal-title">
      These traits are missing from your wallet
    </div>
    <div
      class="ape-constructor-analyze-modal-description"
    >
      They are available in our loot boxes. Grab them now!
    </div>
    <div class="ape-constructor-analyze-modal-items">
      <div
        v-for="item in items"
        :key="item.id"
        class="ape-constructor-analyze-modal-item"
      >
        <div class="ape-constructor-analyze-modal-item__image">
          <img
            :src="item.previewImageSrc"
            :alt="item.name"
          >
        </div>
        <div class="ape-constructor-analyze-modal-item__name">
          {{ item.name }}
        </div>
        <div
          class="ape-constructor-analyze-modal-item__box"
          :class="item.rarity.toLowerCase() === 'legendary' ? 'legendary' : 'common'"
        >
          {{ item.rarity }}
        </div>
      </div>
    </div>
    <div class="ape-constructor-analyze-modal-info">
      OG Mint Traits are not available in the Loot Boxes, You can find them on
      <a
        href="https://opensea.io/collection/bullsandapes-apetraits"
        target="_blank"
      >Opensea</a>.
    </div>
    <a
      href="#"
      class="ape-constructor-analyze-modal-action"
      @click.prevent="openLootBox"
    >OPEN A LOOT BOX</a>
    <div class="ape-constructor-analyze-modal-action-description">
      FOR A CHANCE TO FIND THESE TRAITS
    </div>
  </AModalContent>
</template>

<script>import { computed, defineComponent } from "vue-demi";
import AModalContent from "../Modal/AModalContent.vue";
import IAnalyze from "@/assets/icons/ape-constructor/ape-constructor-analyze.svg?inline"
import { useApeConstructorStore } from "@/store/ape-constructor";
import { useRouter } from "vue-router";
export default defineComponent({
  components: { AModalContent, IAnalyze },
  props: {

  },
  setup(props, { emit }) {
    emit('init', { modalClass: 'ape-constructor-analyze-' })
    const store = useApeConstructorStore()
    const items = computed(() => {
      const traits = store.equippedTraits.filter(trait => !store.isOwnTrait(trait.id))
      // const traits = []
      // for (let i = 0; i < 12; i++) {
      //   traits.push({
      //     categoryId: 9,
      //     categoryName: "Rear Addon",
      //     furSkin: 3,
      //     genderId: 3,
      //     id: 137,
      //     rarity: Math.random() > 0.5 ? 'legendary' : "common",
      //     name: "U-Xiphos Bow",
      //     previewImageSrc: "https://storage.googleapis.com/apes-test/female/rear_addon/femaleape0007_backaddon/skin/femaleape0007_backaddon_preview.png"
      //   })
      // }
      return traits.map(trait => {
        return {
          ...trait,
          rarity: trait.rarity ? trait.rarity.charAt(0).toUpperCase() + trait.rarity.slice(1) : ''
        }
      })
    })
    const router = useRouter()
    const openLootBox = () => {
      router.push({ name: "LootBox" })
      // window.open(router.resolve().fullPath, '_blank')
      emit('close')
    }
    return { items, openLootBox }
  }
})
</script>
<style lang="scss">
.ape-constructor-analyze-modal {
  width: 870px;
  height: 990px;
  max-height: 90%;
  box-sizing: border-box;
  max-width: 100%;
  background: linear-gradient(180deg, #17111f 0%, #22080a 100%);
  padding: 100px;
  padding-bottom: 60px;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  &-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #1e1e1e;
    color: #3d3e3f;
    flex-shrink: 0;
    margin-bottom: 10px;
  }
  &-title {
    font-size: 24px;
    margin-bottom: 5px;
    font-family: var(--font-oxanium);
    text-transform: uppercase;
  }
  &-description {
    color: #454b52;
    font-family: var(--font-oxanium);
    font-size: 18px;
  }
  &-items {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 100%;
    flex: 1;
    margin: 0 -20px;
  }
  &-info {
    margin: 10px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
    opacity: 0.8;
    a {
      color: var(--primary);
    }
  }
  &-item {
    background: #0a0a0a;
    width: calc(100% / 4 - 40px);
    padding: 8px 12px;
    padding-bottom: 20px;
    border-radius: 16px;
    margin: 8px 20px;
    box-sizing: border-box;
    position: relative;
    font-weight: bold;
    font-family: var(--font-montserrat);
    &__name {
      font-size: 14px;
      margin-bottom: 5px;
    }
    &__image {
      margin-bottom: 5px;
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
    &__box {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      padding: 1px;
      font-size: 12px;
      &.common {
        background: #343d4f;
      }
      &.legendary {
        background: var(--ape-constructor-accent);
      }
    }
  }
  &-action {
    flex-shrink: 0;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: var(--font-oxanium);
    font-size: 24px;
    font-weight: 800;
    background-color: var(--primary);
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    margin: 0 -30px;
    transition: 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    &-description {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin-top: 5px;
      opacity: 0.8;
    }
  }
  @media screen and (max-width: 1450px) {
    padding: 50px;
    padding-bottom: 20px;
    max-width: 90%;
    &-icon {
      display: none;
    }
  }
  @media screen and (max-width: 992px) {
    &-items {
      margin: 0 -20px;
    }
    &-item {
      width: calc(100% / 3 - 20px);
      margin: 8px 10px;
    }
  }
  @media screen and (max-width: 576px) {
    padding: 20px;
    &-title {
      font-size: 18px;
      padding-right: 30px;
    }
    &-description {
      font-size: 14px;
    }
    &-action {
      margin: 0;
      
      height: 40px;
      font-size: 18px;
      &-description {
        font-size: 16px;
      }
    }
    &-items {
      margin: 0 -10px;
    }
    &-item {
      width: calc(100% / 2 - 10px);
      margin: 8px 5px;
      &__name {
        font-size: 12px;
      }
    }
  }
}
</style>